import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { billDialogAction } from '../../store/bill/billDialogReducer';
import { createCustomStore, createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect, formatDate, resources } from '../../data/app';
import { DataGrid, Button, TextBox, ScrollView, SelectBox } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem, SearchPanel } from 'devextreme-react/data-grid';
import { cellRender, formatId, formatToMoney, toMoney } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault, defaultLabels } from '../../data/defaultObjects';
import { paymentType, preBillType } from '../../data/enums';
import ProductDDBComponent from '../../components/dropdown/ProductDDBComponent';
import useProducts from '../../hooks/useProducts';
import uri from '../../utils/uri';
import Resumen from '../../components/form/Resumen';
import ClientInfo from '../clients/ClientInfo';
import { confirm } from 'devextreme/ui/dialog';
import  NuevoClient from '../clients/NuevoClient';
import useAuthorization from '../../hooks/useAuthorization';
import useClients from '../../hooks/useClients';
import { clientDetailAction } from '../../store/clientDetail/clientDetailReducer';
import { clientDialogAction } from '../../store/client/clientDialogReducer';
import urlReport from '../../services/reportServices';
import DataSource from 'devextreme/data/data_source';


const Nuevo = props => {

    const [ _, canChangeCreditDays ] = useAuthorization( resources.cambiarDiasCredito );
    const { action, typeId } = props;

    const { billDialog: { open, id, clientId }, user, } = useSelector(store => store);
    const [resumenData, setResumenData] = useState([]);
    const billDefaultCustom = { ...billDefault, clientId: clientId, preBillTypeId: typeId, paymentTypeId: 2 };

    const [ reload, setReload ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ editing, setEditing ] = useState(false);

    const { products, setProducts } = useProducts({ areaId: user.areaId, exists: true, reload });
    const [bill, setBill] = useState({ ...billDefaultCustom });
    const [saving, setSaving] = useState(false);
    const [currentCreditDays, setCurrentCreditDays] = useState(0);

    let refForm = useRef();
    let refGrid = useRef();
    let textBoxRef = useRef();
    let refSelect = useRef(); 

    useEffect(() => {       

        if (id > 0) {
            setLoading(true);
            http(uri.products.getByArea(user.areaId)).asGet().then(data => {
                setProducts(data);

                http(uri.prebills.getById(id)).asGet().then(resp => {

                    resp.preBillDetails.forEach(detail => {

                        let info = products.find(x => x.id == detail.productId);

                        detail['presentation'] = info.presentation;
                        detail['um'] = info.um;
                        detail['family'] = info.family;
                        detail['stock'] = info.stock;
                        detail['product'] = null;

                    });

                    setBill(_bill =>({ ..._bill, ...resp }));
                    setResumenData([...resp.preBillDetails]);
                    setEditing(false);
                    setLoading(false);
                })

            });

        } else {

            setLoading(true);
            setBill({ ...billDefaultCustom, preBillDetails: [] });
            setResumenData([]);   
            setEditing(true);   
            setLoading(false);    

        }

        if(open)
            window.addEventListener("beforeunload", alertUser);
    
        return () => {
            if(open)
                window.removeEventListener("beforeunload", alertUser);
        };

    }, [open]);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
        alert('saliendo');
    };

    const dispatch = useDispatch();

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Agregar producto',
                icon: 'plus',
                type: 'default',
                stylingMode: "outlined",
                disabled: !editing && !currentIsCotizazion,
                onClick: () => refGrid.current.instance.addRow()
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => setReload(Math.random())
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'check',
                type: 'revert',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.cancelEditData();                   
                   
                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'revert',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.cancelEditData();                   
                   
                }
            }
        }
        );
    }    

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        setReload(Math.random());

        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const convertFactura = (print) => {

        let result = confirm("<i>Esta seguro de convertir a factura la preventa: <b>"+formatId(id)+"</b>?</i>", "Confirmar");
        result.then((dialogResult) => {

            if (dialogResult){

                setSaving(true);
                
                http(`${uri.prebills.base}/${id}/convertToBill`).asGet().then(resp => {
                    
                    notify(`Se ha creado la factura correctamente correctamente`);
                    if(print)
                    {
                        const report = urlReport();
                        report.print(`${report.billTicket(resp.billId)}`);
                    }
                    closeDialog(true);     
                    setSaving(false);
                   
                    
                }).catch(err => {                    
                    notify(err, 'error', 5000);
                    setSaving(false);
                });
                
            }
        });

    }


    const guardarAsCotizacion = () => {
        guardarFactura({ preBillTypeId: preBillType.cotizacion });
    }

    const guardarFactura = ({ preBillTypeId = null }) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            let result = refForm.current.instance.validate();

            if (result.isValid) {

                setSaving(true);
                let data = { ...bill };

                if(preBillTypeId)
                    data.preBillTypeId = preBillTypeId;

                http(uri.prebills.insert).asPost(data).then(resp => {
                    setSaving(false);
                    notify(`${action} registrada correctamente`);
                    setReload(Math.random());
                    closeDialog(true);                    
                }).catch(err => {
                    setSaving(false);
                    notify(err, 'error', 6000);
                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['stock'] = info.stock;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['discount'] = 0;
            !currentRowData['quantity'] && (newData['quantity'] = 1);
            !currentRowData['total'] && (newData['total'] = info.price);
        }

        if (prop == 'quantity' && (+value) >= 0) {
            const stock = currentRowData['stock'] + value;
            if(value > stock && isPreventa){
                newData['quantity'] = currentRowData['stock'];
                newData['total'] = currentRowData['customPrice'] * currentRowData['stock'];
                notify(stock + " máximo stock disponible", "error", 5000);
            }else{
                newData['total'] = currentRowData['customPrice'] * value;
            }
        }

        if (prop == 'customPrice' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

        if (prop == 'discount' && (+value) >= 0) {
            newData['total'] = (currentRowData['quantity'] *  currentRowData['customPrice']) - value;
        }

    }

    const onCellPrepared = e => {

        const cellsQuantity = ['quantity', 'quantityRequest']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');
            if (e.column.dataField == 'customPrice')
                e.cellElement.classList.add('customPrice-text');

        }

    }

    const onRowInserted = e => {
        setResumenData([...bill.preBillDetails])
    }

    const isNew = id == 0;

    const init = "";      


    const reloadClient = clientId => {
        setBill(_bill => ({ ..._bill, clientId: clientId }));
    }

    const onRowInserting = (e) => {

        const isCanceled = new Promise((resolve, reject) => {

            const existsProduct = bill.preBillDetails.find(x => x.productId == e.data.productId);

            if (existsProduct) {
                notify(`El producto ya se encuentra en la lista`, 'error', 5000);
                reject(`El producto ya se encuentra en la lista`);
            }else{
                resolve(false);
            }
        });
        e.cancel = isCanceled;

    }

    const paymentTypeIdChanged = (e) => {

        setBill(_bill => ({ 
            ..._bill, paymentTypeId: e.value, 
            paymentMethodId: e.value == paymentType.credito ? null : '', 
            bankId: e.value == paymentType.credito ? null : '', 
            reference:'', 
            creditDays: e.value == paymentType.contado ? 0 : currentCreditDays,
        }))
    }

    const clientIdChanged = e => {

        const client = e.component.option('items')
            .find(x => x.id == e.value);

        let creditDays = 0;

        if(client)
            creditDays = client.creditDays;                 
        
        dispatch(clientDetailAction( {clientId:e.value}));
        setCurrentCreditDays(creditDays);
        setBill(_bill => ({ ..._bill, clientId: e.value, creditDays: creditDays }));

    }

    const buttonOptions2 = {
        icon: 'edit',
        onClick: e => setEditing(!editing),
    };

    const buttonOptions = {
        text: 'Crear Cliente',
        icon: 'user',
        onClick: function(e) { 
            dispatch(clientDialogAction({ open: true }));
        }
    };

    const ds = useMemo(() =>new DataSource({
        key: 'productId',
        store: bill.preBillDetails,
        // ...
    }));


    const addProductById = ( id, _info=null ) => {

        if (!id) return;

        const items = ds.items();

        const existsProduct = items.find(x => x.productId == id);

        if (existsProduct) {
            notify(`El producto ya se encuentra en la lista`, 'error', 5000);
            return
        }

        let info = _info == null ? products.find(x => x.id == id) : _info;

        if(info.stock <= 0)
            return notify('No hay stock disponible', 'error', 5000);

        let newData = {}; 

        newData['presentation'] = info.presentation;
        newData['um'] = info.um;
        newData['family'] = info.family;
        newData['stock'] = info.stock;
        newData['price'] = info.price;
        newData['customPrice'] = info.price;
        newData['cost'] = info.cost;
        newData['quantity'] = 1;
        newData['total'] = info.price;         
        newData['discount'] = 0;
        newData['productId'] = info.id;  

        ds.store().insert(newData).then(() => {
            ds.reload().then(() => {
                refGrid.current.instance.editCell(bill.preBillDetails.length - 1, 'quantity');
            });
        });

        //setBill(_bill => ({ ..._bill, billDetails: [...newDetail],}));

    }

    const dataSourceProduct = useCallback(() => new DataSource({
        load: (loadOptions) => {
    
            let params = {};
            params.skip = loadOptions.skip || 0;
            params.take = loadOptions.take || 10;
    
            if(loadOptions.searchValue)
                params.name = loadOptions.searchValue;

            params.exists = true;
            params.active=false; 
            params.has=false;
            params.onlyProducts= false;
    
            return http(uri.products.getByArea(user.areaId)+'/grid')
            .asGet(params).then(x => x.items);
            
        },
        byKey: id => http(uri.products.getById(id)).asGet(),
        paginate : true,
        pageSize: 10
    }),[]);

    const setFocus = () => {
        setTimeout(() => {
            refSelect.current.instance.focus();        
        }, 300);
    }

    const onEditCanceled = (e) => {
        setFocus()
    }

    const keyDown = (e) => {
        if(e.event.key == 'Enter')
            setFocus()
    }

    const itemRender = (data) => {

        const isZero = data.stock <= 0;

        const cssZero = isZero ? 'product-stock zero-stock' : 'product-stock';

        return <div className="d-flex space-between">
            <div>
                <div className="product-name"><b>{data.internalCode}</b>-{data.name}</div>
                <div className="product-desciption"><small className='text-muted'>Categoria: </small>{data.family} <small className='text-muted'>Marca: </small>{data.presentation}</div>
            </div>  
            <div className="text-right">
                <div className="product-price">{formatToMoney(data.price)}</div>
                <div className={cssZero}><small className='text-muted'>Disponible:</small> {data.stock}/{data.disponible}</div>
            </div>    
        </div>
    }


    const isPreventa = typeId == preBillType.preventa;

    const currentIsPreventa = bill.preBillTypeId == preBillType.preventa;

    const currentIsFacturacion = bill.preBillTypeId == preBillType.facturacion;

    const currentIsCotizazion = bill.preBillTypeId == preBillType.cotizacion;
   
    return (
        <div>
            <NuevoClient onCreate={reloadClient} />
            <Popup
                width={1050}
                height={'100vh'}
                title={isNew ? `Nueva ${action}` : `Ver ${action} ${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
                id='bill-panel'
            >
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#bill-panel' }}
                    visible={loading}
                    showIndicator={loading}
                    shading={true}
                    showPane={true}
                />
                <ToolbarItem
                    widget="dxButton" 
                    location="after"
                    visible={!isNew && currentIsPreventa} 
                    options={buttonOptions2}>

                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    location="after"
                    options={buttonOptions}>
                </ToolbarItem>
                <ScrollView showScrollbar='never' > 
                    <Form formData={bill} ref={refForm} readOnly={!editing && !currentIsCotizazion}>
                        <GroupItem colCount={4}>
                            <GroupItem colSpan={4}>
                                <GroupItem colCount={6}>

                                    <SimpleItem dataField="date" colSpan={2} editorType="dxDateBox"
                                        editorOptions={{
                                            displayFormat: formatDate,
                                            openOnFieldClick: true,
                                        }} >
                                        <Label text="Fecha" />
                                        <RequiredRule message="Seleccione la fecha" />
                                    </SimpleItem>
                                    <SimpleItem dataField="paymentTypeId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: createStoreLocal({ name: 'billPaymentType' }),
                                            ...editorOptionsSelect,
                                            searchEnabled: false,    
                                            onValueChanged: paymentTypeIdChanged 
                                        }} >
                                        <Label text="Tipo pago" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>
                                    <SimpleItem dataField="codigo" colSpan={2} editorType="dxTextBox"
                                        editorOptions={{ placeholder: 'Talonario', showClearButton: true }} >
                                        <Label text="Referencia" />
                                    </SimpleItem>
                                    <SimpleItem dataField="clientId" colSpan={4} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource:  createCustomStore({ url: uri.clients.base+'/catalogo?reload='+reload, active: true })(),
                                            valueExpr:"id",
                                            displayExpr: item => item ? `${item.id} - ${item.name}` : '',
                                            searchEnabled: true,
                                            showClearButton:true,
                                            searchExpr: ["name"],
                                            onValueChanged: clientIdChanged
                                        }} >
                                        <Label text="Cliente" />
                                        <RequiredRule message="Seleccione el cliente" />
                                    </SimpleItem>
                                    <SimpleItem dataField="creditDays" colSpan={2} editorType="dxNumberBox"
                                        editorOptions={{
                                            disabled: !canChangeCreditDays,
                                            onValueChanged: e => setBill(_bill => ({ ..._bill, creditDays: e.value, })),
                                        }} >
                                        <Label text="Dias crédito" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>                                
                                    <SimpleItem colSpan={6} >
                                        <ClientInfo user={bill.createBy} />
                                    </SimpleItem>                               
                                    <SimpleItem dataField="paymentMethodId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            disabled: bill.paymentTypeId == paymentType.credito,
                                            dataSource: createStoreLocal({ name: 'billPaymentMethod' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: e => setBill(_bill => ({ ..._bill, paymentMethodId: e.value, }))
                                        }} >
                                        <Label text="Forma pago" />
                                    </SimpleItem>
                                    <SimpleItem dataField="bankId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            disabled: bill.paymentTypeId == paymentType.credito,
                                            dataSource: createStoreLocal({ name: 'bank' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: e => setBill(_bill => ({ ..._bill, bankId: e.value, }))
                                        }} >
                                        <Label text="Banco" />
                                    </SimpleItem>
                                    <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                                        editorOptions={{ showClearButton: true, disabled: bill.paymentTypeId == paymentType.credito, }} >
                                        <Label text="Referencia" />
                                    </SimpleItem>

                                </GroupItem>
                            </GroupItem>

                        </GroupItem>
                        <GroupItem colCount={4}>
                            <SimpleItem colSpan={2}>
                                <SelectBox
                                    ref={refSelect}
                                    dataSource={dataSourceProduct()} 
                                    valueExpr="id" 
                                    displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}
                                    searchEnabled={true} 
                                    disabled={!editing && !currentIsCotizazion}
                                    searchMode="contains" searchExpr={['name','internalCode']} 
                                    searchTimeout={600} 
                                    placeholder="Selecione una producto" 
                                    showClearButton={true}         
                                    itemRender={itemRender}                           
                                    onValueChanged={e => {

                                        addProductById(e.value);
                                        e.component.reset();
                                        e.component.focus();                                       

                                    }} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <DataGrid id="gridDetails"
                                ref={refGrid}
                                selection={{ mode: 'single' }}
                                dataSource={ds}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                height={270}
                                onToolbarPreparing={onToolbarPreparing}
                                onRowInserted={onRowInserted}
                                onRowRemoved={onRowInserted}
                                onRowUpdated={onRowInserted}
                                onCellPrepared={onCellPrepared}
                                onRowInserting={onRowInserting}
                                onEditCanceled={onEditCanceled}                              
                                onKeyDown={keyDown}
                            >
                                <SearchPanel visible={true} />
                                <Column dataField="productId" caption="Producto"
                                    setCellValue={setCellValue.bind(null, "productId")}
                                    editCellComponent={props => <ProductDDBComponent showPrice={true} {...props}  validateQuantity={isPreventa} />}
                                >
                                
                                    <Lookup
                                        dataSource={products}
                                        valueExpr="id"
                                        displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                                    />
                                    <RuleRequired />
                                </Column>                           
                                <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="quantity" caption="Cantidad" dataType="number" width={80}
                                    setCellValue={setCellValue.bind(null, "quantity")}
                                >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="stock" caption="Disponible" dataType="number" width={90} allowEditing={false}>
                                </Column>
                                <Column visible={false} dataField="price" caption="Precio" dataType="number" width={100} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="customPrice" caption="Precio" dataType="number" width={100} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "customPrice")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="discount" caption="Desc" dataType="number" width={80} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "discount")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column type="buttons" width={50}>
                                    <ButtonGrid name="delete" />
                                </Column>      
                                <Summary>
                                    <TotalItem  column="productId" summaryType="count" />
                                    <TotalItem  column="discount" summaryType="sum" customizeText={toMoney}  />
                                    <TotalItem column="total" summaryType="sum" customizeText={toMoney} />
                                </Summary>                         
                                <Editing
                                    mode="cell"
                                    allowDeleting={editing || currentIsCotizazion}
                                    allowUpdating={editing || currentIsCotizazion}
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                ></Editing>
                            </DataGrid>
                        </GroupItem>
                        <GroupItem colCount={6}>
                            <SimpleItem colSpan={4} dataField="observation" editorType="dxTextBox">
                                <Label text="Observacion" />
                                <StringLengthRule max={150} message="Maximo 150 caracteres" />
                            </SimpleItem>
                            <SimpleItem colSpan={2} dataField="codeAuth" editorType="dxTextBox">
                                <Label text="Código Autorización" />
                                <StringLengthRule max={150} message="Maximo 150 caracteres" />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                    <br />
                    <div className='flex-v'>
                        {bill.active == false &&  <label className="text-danger pa-canceled">Registro Anulado</label>}
                        {
                            !isPreventa &&  <Button
                                className="mt-10"
                                text={saving ? 'Guardando...' : `Guardar ${action}`}
                                type="success"
                                icon='save'
                                disabled={saving }
                                onClick={guardarFactura}
                                />
                        }{
                            currentIsPreventa && <>
                                <div className="flex-end">

                                    <Button
                                        className="mt-10"
                                        text={saving ? 'Guardando...' : `Convertir a factura`}
                                        type="default"
                                        icon='save'
                                        visible={!isNew && isPreventa }
                                        disabled={saving || editing || bill.active == false}
                                        onClick={() => convertFactura()}
                                        />
                                    <Button
                                        className="mt-10 ml-10"
                                        text={saving ? 'Guardando...' : `Convertir a factura e Imprimiir`}
                                        type="default"
                                        icon='save'
                                        visible={!isNew && isPreventa }
                                        disabled={saving || editing || bill.active == false}
                                        onClick={() => convertFactura(true)}
                                        />
                                </div>
                                <div className="flex-end">
                                <Button
                                        className="mt-10 ml-10"
                                        text={saving ? 'Guardando...' : `Guardar como cotización`}
                                        type="primary"
                                        icon='save'
                                        visible={isNew}
                                        disabled={saving || !editing || bill.active == false}
                                        onClick={e => guardarAsCotizacion()}
                                        />
                                    <Button
                                        className="mt-10"
                                        text={saving ? 'Guardando...' : `Guardar ${action}`}
                                        type="success"
                                        icon='save'
                                        disabled={saving || !editing || bill.active == false}
                                        onClick={guardarFactura}
                                        />
                                  
                                </div>
                                </>
                        }
                        
                    </div>
                </ScrollView>
            </Popup>
        </div>
    );
}

export default Nuevo;
