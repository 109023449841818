import React, { useEffect, useState, useRef } from 'react';
import Form, { SimpleItem, GroupItem, Label, RequiredRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect, formatDate } from '../../data/app';
import { DataGrid, Button, TextBox } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem } from 'devextreme-react/data-grid';
import { cellRender, formatToMoney } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault, defaultLabels } from '../../data/defaultObjects';
import ProductDDBComponent from '../../components/dropdown/ProductDDBComponent';
import useProducts from '../../hooks/useProducts';
import uri from '../../utils/uri';
import Title from '../../components/shared/Title';
import BlockHeader from '../../components/shared/BlockHeader';
import Resumen from '../../components/form/Resumen';
import moment from 'moment';

const NuevoQuickly = props => {

    const { user, appInfo } = useSelector(store => store);

    const billDefaultCustom = { ...billDefault, clientId: 0, paymentTypeId: 1, nameClient: 'Cliente de contado', billDetails: [] };

    const [ reload, setReload ] = useState(0);
    const { products } = useProducts({ areaId: user.areaId, exists: true, reload });
    const [bill, setBill] = useState({ ...billDefaultCustom });
    const [saving, setSaving] = useState(false);
    const [resumenData, setResumenData] = useState([]);

    let refForm = useRef();
    let refGrid = useRef();
    let textBoxRef = useRef();

    const dispatch = useDispatch();
    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Agregar articulo',
                icon: 'plus',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.addRow()
            }
        });
    }

    const guardarFactura = (e) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            result = refForm.current.instance.validate();

            if (result.isValid) {

                setSaving(true);
                let data = { ...bill, clientId: appInfo.clientDefault };

                http(uri.bills.insert).asPost(data).then(resp => {

                    setSaving(false);                   
                    notify(`Factura registrada correctamente`);
                    setBill({ ...billDefaultCustom });
                    setResumenData([]);
                    setReload(Math.random());
                    //routeReset(props);

                }).catch(err => {
                    setSaving(false);
                    notify(err, 'error', 5000);
                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);
            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['discount'] = 0;
            !currentRowData['quantity'] && (newData['quantity'] = 1);
            !currentRowData['total'] && (newData['total'] = info.price);
        }

        if (prop == 'quantity' && (+value) >= 0) {
            newData['total'] = currentRowData['customPrice'] * value;
        }

        if (prop == 'customPrice' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

        if (prop == 'discount' && (+value) >= 0) {
            newData['total'] = (currentRowData['quantity'] *  currentRowData['customPrice']) - value;
        }

    }

    // useEffect(() => {
    //     if (appInfo.rate == 0) {
    //         notify('Debe ingresar la tasa de cambio del dia para poder facturar', 'warning', 5000)
    //         props.history.push('/app/tasa-de-cambio');
    //     }
    // }, [appInfo]);

    const onCellPrepared = e => {

        const cellsQuantity = ['quantity', 'quantityRequest']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');
            if (e.column.dataField == 'customPrice')
                e.cellElement.classList.add('customPrice-text');
        }

    }

    const onKeyDown = (e) => {       
        
        if(e?.event?.key == 'Enter'){

            const code = e.event.target.value;

            let info = products.find(x => x.internalCode == code);
            let newData = {};           
            

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['quantity'] = 1;
            newData['total'] = info.price;         
            newData['discount'] = 0;         
            newData['productId'] = info.id;   

            const newDetail = [...bill.billDetails, newData ];

            setBill({ ...bill, billDetails: [...newDetail] });
            setResumenData([...newDetail]); 

        }

    }

    const onRowInserted = e => {
        setResumenData([...bill.billDetails])
    }

    const title = 'Nueva factura';

    const init = "";

    // useEffect(() => {

    //    http('catalogos/lots').asGet().then(_lots => {
    //      const lotsMap = _lots.map(x => ({ ...x, name: `${x.lote}-${moment(x.expireDate).format('DD-YYYY')}` }));
    //      setLots(lotsMap);
    //    });

    // },[reload]);

    useEffect(() => {

        window.addEventListener("beforeunload", alertUser);
        return () => {
        window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
        alert('saliendo');
      };

    return (
        <div className="container medium">
            <Title title={title} />
            <BlockHeader title={title} />
            <Form formData={bill} ref={refForm}>
                <GroupItem colCount={3}>
                    <GroupItem colSpan={2}>
                        <GroupItem colCount={3}>

                            <SimpleItem dataField="date" editorType="dxDateBox"
                                editorOptions={{
                                    displayFormat: formatDate,
                                    disabled: true
                                }} >
                                <Label text="Fecha" />
                                <RequiredRule message="Seleccione la fecha" />
                            </SimpleItem>
                            <SimpleItem dataField="paymentTypeId" editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: createStoreLocal({ name: 'billPaymentType' }),
                                    ...editorOptionsSelect,
                                    disabled: true
                                }} >
                                <Label text="Pago" />
                                <RequiredRule message="Seleccione el tipo" />
                            </SimpleItem>
                            <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                                editorOptions={{ placeholder: 'Factura No', showClearButton: true }} >
                                <Label text="Referencia" />
                            </SimpleItem>
                            <SimpleItem dataField="nameClient" colSpan={3} editorType="dxTextBox"
                                editorOptions={{ placeholder: 'Cliente de contado', showClearButton: true }} >
                                <Label text="Cliente" />
                            </SimpleItem>
                           
                        </GroupItem>
                    </GroupItem>
                    <GroupItem colSpan={1}>
                        <Resumen data={resumenData} />
                    </GroupItem>

                </GroupItem>
                <GroupItem>
                    <TextBox focusStateEnabled={true} ref={textBoxRef} onKeyDown={onKeyDown} value={init}></TextBox>
                    <DataGrid id="gridDetails"
                        ref={refGrid}
                        selection={{ mode: 'single' }}
                        dataSource={bill.billDetails}
                        showBorders={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        height={290}
                        onToolbarPreparing={onToolbarPreparing}
                        onRowInserted={onRowInserted}
                        onRowRemoved={onRowInserted}
                        onRowUpdated={onRowInserted}
                        onCellPrepared={onCellPrepared}
                    >
                        <Column dataField="productId" caption="Producto"
                            setCellValue={setCellValue.bind(null, "productId")}
                            editCellComponent={props => <ProductDDBComponent showPrice={true} {...props} />}
                        >
                            <Lookup
                                dataSource={products}
                                valueExpr="id"
                                displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                            />
                            <RuleRequired />
                        </Column>                       
                        <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                            <RuleRequired />
                        </Column>
                        <Column dataField="quantity" caption="Cantidad" dataType="number" width={80}
                            setCellValue={setCellValue.bind(null, "quantity")}
                        >
                            <RuleRequired />
                        </Column>
                        <Column visible={false} dataField="price" caption="Precio" dataType="number" width={100} allowEditing={false} cellRender={cellRender()} >
                            <RuleRequired />
                        </Column>
                        <Column dataField="customPrice" caption="Precio" dataType="number" width={100} cellRender={cellRender()}
                            setCellValue={setCellValue.bind(null, "customPrice")}>
                            <RuleRequired />
                        </Column>
                        <Column dataField="discount" caption="Desc" dataType="number" width={80} cellRender={cellRender()}
                            setCellValue={setCellValue.bind(null, "discount")}>
                            <RuleRequired />
                        </Column>
                        <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                            <RuleRequired />
                        </Column>
                        <Column type="buttons" width={50}>
                            <ButtonGrid name="delete" />
                        </Column>
                        <Summary>
                            <TotalItem
                                column="total"
                                summaryType="sum"
                                customizeText={data => formatToMoney(data.value)} />
                        </Summary>
                        <Editing
                            mode="cell"
                            allowDeleting={true}
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            useIcons={true}
                        ></Editing>
                    </DataGrid>
                </GroupItem>
            </Form>
            <Button
                className="mt-10"
                text={saving ? 'Guardando...' : `Guardar factura`}
                type="success"
                icon='save'
                disabled={saving}
                onClick={guardarFactura}
            />


        </div>
    );
}

export default NuevoQuickly;
