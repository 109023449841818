import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { SelectBox } from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { billDialogAction } from '../../store/bill/billDialogReducer';
import { createStoreLocal } from '../../utils/proxy';
import { editorOptionsSelect, formatDate, resources } from '../../data/app';
import { DataGrid, Button, TextBox, DateBox, ScrollView } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid, Summary, TotalItem,Toolbar, Selection, SearchPanel, Scrolling} from 'devextreme-react/data-grid';
import { cellRender, formatId, formatToMoney, toMoney } from '../../utils/common';
import { LoadPanel } from 'devextreme-react/load-panel';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault, defaultLabels } from '../../data/defaultObjects';
import { paymentType } from '../../data/enums';
import ProductDDBComponent from '../../components/dropdown/ProductDDBComponent';
import useProducts from '../../hooks/useProducts';
import uri from '../../utils/uri';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import useAuthorization from '../../hooks/useAuthorization';
import urlReport from '../../services/reportServices';
import useUsers from '../../hooks/useUsers';

const Nuevo = props => {

    const [ _, canChangeCreditDays ] = useAuthorization( resources.cambiarDiasCredito );
    const { billDialog: { open, id, clientId }, user, } = useSelector(store => store);
    const billDefaultCustom = { ...billDefault, clientId: clientId };

    const [ reload, setReload ] = useState(0);

    const { products, setProducts } = useProducts({ areaId: user.areaId, exists: true, reload });
    const [bill, setBill] = useState({ ...billDefaultCustom, billDetails: []});
    const [saving, setSaving] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [lots, setLots] = useState([]);   
    const { users } = useUsers();
    const [currentCreditDays, setCurrentCreditDays] = useState(0);

    let clientInfo = {};

    let refForm = useRef();
    let refGrid = useRef();
    let textBoxRef = useRef();
    let refDeliveryDate = useRef();   
    let refSelect = useRef(); 

    useEffect(() => {
        
        //     if (appInfo.rate == 0) {
        //         dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        //         notify('Debe ingresar la tasa de cambio del dia para poder facturar', 'warning', 5000)
        //         props.history.push('/app/tasa-de-cambio');
        //     }

        if (id > 0) {
            setLoading(true);
            http(uri.products.getByArea(user.areaId)).asGet().then(data => {
                setProducts(data);

                http(uri.bills.getById(id)).asGet().then(resp => {

                    resp.billDetails.forEach(detail => {

                        let info = products.find(x => x.id == detail.productId);

                        detail['presentation'] = info.presentation;
                        detail['um'] = info.um;
                        detail['family'] = info.family;
                        detail['stock'] = info.stock;

                    });

                    setBill(_bill => ({ ..._bill, ...resp, }));
                    if (open) textBoxRef.current.instance.focus();
                    setLoading(false);
                })

            });

        } else {
            setLoading(true);
            setBill({ ...billDefaultCustom, billDetails: [] });
            if (open) textBoxRef.current.instance.focus();
            setLoading(false);

        }

        

        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
      
    
       
    }, [open]);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
        alert('saliendo');
    };


    useEffect(() => {
        if (open) textBoxRef.current.instance.focus();
    }, [open]);

    const dispatch = useDispatch();
    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                text: 'Agregar articulo',
                icon: 'plus',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => refGrid.current.instance.addRow()
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                type: 'default',
                stylingMode: "outlined",
                onClick: () => setReload(Math.random())
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'remove',
                type: 'danger',
                stylingMode: "outlined",
                onClick: () => {             

                    var selectedRow = refGrid.current.instance.getSelectedRowKeys();                   
                    if (selectedRow.length > 0) {
                        let key = selectedRow[0]
                        let index = refGrid.current.instance.getRowIndexByKey(key)
                        refGrid.current.instance.deleteRow(index);
                    }
                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'check',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.saveEditData();                   
                   
                }
            }
        },{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'revert',
                type: 'normal',
                stylingMode: "outlined",
                onClick: () => {                  

                    refGrid.current.instance.cancelEditData();                   
                   
                }
            }
        }
        );
    }

    const ds = useMemo(() =>new DataSource({
        key: 'productId',
        store: bill.billDetails,
        // ...
    }));

    const addProductById = ( id, _info=null ) => {

        if (!id) return;

        const items = ds.items();

        const existsProduct = items.find(x => x.productId == id);

        if (existsProduct) {
            notify(`El producto ya se encuentra en la lista`, 'error', 5000);
            return
        }

        let info = _info == null ? products.find(x => x.id == id) : _info;

        if(info.stock <= 0)
            return notify('No hay stock disponible', 'error', 5000);

        let newData = {}; 

        newData['presentation'] = info.presentation;
        newData['um'] = info.um;
        newData['family'] = info.family;
        newData['stock'] = info.stock;
        newData['price'] = info.price;
        newData['customPrice'] = info.price;
        newData['cost'] = info.cost;
        newData['quantity'] = 1;
        newData['total'] = info.price;         
        newData['discount'] = 0;
        newData['productId'] = info.id;  

        const newDetail = [...bill.billDetails, newData ];

        ds.store().insert(newData).then(() => {
            ds.reload().then(() => {
                refGrid.current.instance.editCell(newDetail.length - 1, 'quantity');
            });
        });

        //setBill(_bill => ({ ..._bill, billDetails: [...newDetail],}));

    }

    const addProductByCode = code => {
        let info = products.find(x => x.internalCode == code);

        if (info)
            addProductById(info.id, info);

    }

    const itemRender = (data) => {

        const isZero = data.stock <= 0;

        const cssZero = isZero ? 'product-stock zero-stock' : 'product-stock';

        return <div className="d-flex space-between">
            <div>
                <div className="product-name"><b>{data.internalCode}</b>-{data.name}</div>
                <div className="product-desciption"><small className='text-muted'>Categoria: </small>{data.family} <small className='text-muted'>Marca: </small>{data.presentation}</div>
            </div>  
            <div className="text-right">
                <div className="product-price">{formatToMoney(data.price)}</div>
                <div className={cssZero}><small className='text-muted'>Disponible:</small> {data.stock}/{data.disponible}</div>
            </div>    
        </div>
    }

    const onKeyDown = (e) => {
        
        if(e?.event?.key == 'Enter'){

            const code = e.event.target.value;

            addProductByCode(code);

        }

    }

    const closeDialog = (load) => {

        refForm.current.instance.resetValues();
        refGrid.current.instance.cancelEditData();

        dispatch(billDialogAction({ id: 0, clientId: 0, open: false }));
        if (load) {
            let { onSave } = props;
            onSave();
        }
    }

    const onHiding = ({ load }) => {
        closeDialog(load);
    }

    const guardarFactura = (e) => {

        refGrid.current.instance.saveEditData();
        let result = refGrid.current.instance.hasEditData();

        if (!result) {

            let result = refForm.current.instance.validate();

            if (result.isValid) {

                let result = confirm("<i>Esta seguro de guardar la factura?</i>", "Confirmar");

                result.then((dialogResult) => {
                    if (dialogResult) {
                        
                        setSaving(true);
                        let data = { ...bill };
                        
                        http(uri.bills.insert).asPost(data).then(resp => {
                            setSaving(false);
                            notify(`Factura registrada correctamente`);
                            setReload(Math.random());
                            closeDialog(true);                    
                        }).catch(err => {
                            setSaving(false);
                            notify(err, 'error', 5000);
                        });

                    }
                });

            }
        }

    }

    const setCellValue = (prop, newData, value, currentRowData) => {

        newData[prop] = value || 0;
        if (prop == 'productId' && value) {

            let info = products.find(x => x.id == value);

            newData['presentation'] = info.presentation;
            newData['um'] = info.um;
            newData['family'] = info.family;
            newData['stock'] = info.stock;
            newData['price'] = info.price;
            newData['customPrice'] = info.price;
            newData['cost'] = info.cost;
            newData['discount'] = 0;
            !currentRowData['quantity'] && (newData['quantity'] = 1);
            !currentRowData['total'] && (newData['total'] = info.price);
        }

        if (prop == 'quantity' && (+value) >= 0) {
            if(value > currentRowData['stock']){
                newData['quantity'] = currentRowData['stock'];
                newData['total'] = currentRowData['customPrice'] * currentRowData['stock'];
                notify(currentRowData['stock'] + " máximo stock disponible", "error", 5000);
            }else{
                newData['total'] = currentRowData['customPrice'] * value;
            }
        }

        if (prop == 'customPrice' && (+value) >= 0) {
            newData['total'] = currentRowData['quantity'] * value;
        }

        if (prop == 'discount' && (+value) >= 0) {
            newData['total'] = (currentRowData['quantity'] *  currentRowData['customPrice']) - value;
        }

    }

    const onCellPrepared = e => {

        const cellsQuantity = ['quantity', 'quantityRequest']

        if (e.rowType == 'data' && e.column.allowEditing) {
            if (cellsQuantity.includes(e.column.dataField))
                e.cellElement.classList.add('quantity-text');
            if (e.column.dataField == 'customPrice')
                e.cellElement.classList.add('customPrice-text');

        }

    }

    const isNew = id == 0;

    const init = "";

    useEffect(() => {

        http('catalogos/lots').asGet().then(_lots => {
          const lotsMap = _lots.map(x => ({ ...x, name: `${x.lote}-${moment(x.expireDate).format('DD-YYYY')}` }));
          setLots(lotsMap);
        });
 
    },[reload]);

    const paymentTypeIdChanged = (e) => {

        setBill(_bill => ({ 
            ..._bill, paymentTypeId: e.value, 
            paymentMethodId: e.value == paymentType.credito ? null : '', 
            bankId: e.value == paymentType.credito ? null : '', 
            reference:'', 
            creditDays: e.value == paymentType.contado ? 0 : currentCreditDays,
        }))
    }

    const clientIdChanged = (e) => {

        const client = e.component.option('items')
            .find(x => x.id == e.value);

        let creditDays = 0;
    
        if(client)
            creditDays = client.creditDays;                 
        
        setCurrentCreditDays(creditDays);        
        setBill(_bill => ({ ..._bill, clientId: e.value, creditDays: creditDays }));
        
    }
    
    const dataSource = useCallback(() => new DataSource({
        load: (loadOptions) => {

            let params = {};
            params.skip = loadOptions.skip || 0;
            params.take = loadOptions.take || 10;

            if(loadOptions.searchValue)
                params.name = loadOptions.searchValue;

            return http(uri.clients.asCatalog)
            .asGet(params).then(x => x.items);
            
        },
        byKey: id => {

            return new Promise((resolve, reject) => {
               
                if(id == 0)
                    resolve(null);
                else{
                    if(clientInfo?.id == id){
                        resolve(clientInfo);
                    }else{
                        http(uri.clients.getById(id)).asGet().then(resp => {
                            clientInfo = resp;
                            return resolve(resp);
                        });
                    }
                }
            });
            
        },       
        paginate : true,
        pageSize: 10
    }),[]);

    const onRowInserting = (e) => {

        const isCanceled = new Promise((resolve, reject) => {

            const items = ds.items();

            const existsProduct = items.find(x => x.productId == e.data.productId);

            if (existsProduct) {
                notify(`El producto ya se encuentra en la lista`, 'error', 5000);
                reject(`El producto ya se encuentra en la lista`);
            }else{
                resolve(false);
            }
        });
        e.cancel = isCanceled;

    }

    const updateDeliveryDate = (e) => {
            
            const deliveryDate = refDeliveryDate.current.instance.option('value');
            const id = bill.id;
            const data = { id, deliveryDate, username: bill.asignedTo };
    
            setSaving(true);
            http(uri.bills.base + '/update').asPost(data).then(resp => {
                notify('Fecha de entrega actualizada correctamente'); 
                setSaving(false);
                closeDialog(true);          
            }).catch(err => {
                notify(err, 'error', 5000);
            });
    }
    
    const printBill = (e) => {
        const report = urlReport();
        report.print(`${report.billTicket(bill.id)}`);
    }

    const dataSourceProduct = useCallback(() => new DataSource({
        load: (loadOptions) => {
    
            let params = {};
            params.skip = loadOptions.skip || 0;
            params.take = loadOptions.take || 10;
    
            if(loadOptions.searchValue)
                params.name = loadOptions.searchValue;

            params.exists = true;
            params.active=false; 
            params.has=false;
            params.onlyProducts= false;
    
            return http(uri.products.getByArea(user.areaId)+'/grid')
            .asGet(params).then(x => x.items);
            
        },
        byKey: id => http(uri.products.getById(id)).asGet(),
        paginate : true,
        pageSize: 10
    }),[]);

    const setFocus = () => {
        setTimeout(() => {
            refSelect.current.instance.focus();        
        }, 300);
    }

    const onEditCanceled = (e) => {
        setFocus()
    }

    const keyDown = (e) => {
        if(e.event.key == 'Enter')
            setFocus()
    }

    return (
        <div>
            <Popup
                width={'90vw'}
                height={'98vh'}
                title={isNew ? `Nueva factura` : `Ver factura ${formatId(id)}`}
                onHiding={onHiding}
                visible={open}
                className="bg-fbfbfb"
                id='bill-panel'
            >
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#bill-panel' }}
                    visible={loading}
                    showIndicator={loading}
                    shading={true}
                    showPane={true}
                />
                <ScrollView showScrollbar='never' > 
                    <Form formData={bill} ref={refForm}>
                        <GroupItem colCount={4}>
                            <GroupItem colSpan={4}>
                                <GroupItem colCount={6}>

                                    <SimpleItem dataField="date" colSpan={2} editorType="dxDateBox"
                                        editorOptions={{
                                            displayFormat: formatDate,
                                            openOnFieldClick: true,
                                        }} >
                                        <Label text="Fecha" />
                                        <RequiredRule message="Seleccione la fecha" />
                                    </SimpleItem>
                                    <SimpleItem dataField="paymentTypeId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: createStoreLocal({ name: 'billPaymentType' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: paymentTypeIdChanged 
                                        }} >
                                        <Label text="Tipo pago" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>
                                    <SimpleItem dataField="codigo" colSpan={2} editorType="dxTextBox"
                                        editorOptions={{ placeholder: 'Talonario', showClearButton: true }} >
                                        <Label text="Referencia" />
                                    </SimpleItem>
                                    <SimpleItem dataField="clientId" colSpan={4} editorType="dxSelectBox"
                                        editorOptions={{
                                            dataSource: dataSource(),
                                            displayExpr: 'fullName',
                                            valueExpr:"id",
                                            displayExpr: item => item ? `${item.id} - ${item.name} ${item.lastName}` : '',
                                            searchEnabled: true,
                                            searchExpr: ["name", "lastName"],
                                            showClearButton:true, onValueChanged: clientIdChanged}} >
                                        <Label text="Cliente" />
                                        <RequiredRule message="Seleccione el cliente" />
                                    </SimpleItem>
                                    <SimpleItem dataField="creditDays" colSpan={2} editorType="dxNumberBox"
                                        editorOptions={{
                                            disabled: !canChangeCreditDays,
                                            onValueChanged: e => setBill(_bill => ({ ..._bill, creditDays: e.value, })),
                                        }} >
                                        <Label text="Dias crédito" />
                                        <RequiredRule message="Seleccione el tipo" />
                                    </SimpleItem>                                

                                    <SimpleItem dataField="paymentMethodId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            disabled: bill.paymentTypeId == paymentType.credito,
                                            dataSource: createStoreLocal({ name: 'billPaymentMethod' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: e => setBill(_bill =>({ ..._bill, billPaymentTypeId: e.value, }))
                                        }} >
                                        <Label text="Forma pago" />
                                    </SimpleItem>
                                    <SimpleItem dataField="bankId" colSpan={2} editorType="dxSelectBox"
                                        editorOptions={{
                                            disabled: bill.paymentTypeId == paymentType.credito,
                                            dataSource: createStoreLocal({ name: 'bank' }),
                                            ...editorOptionsSelect,
                                            onValueChanged: e => setBill(_bill =>({ ..._bill, bankId: e.value, }))
                                        }} >
                                        <Label text="Banco" />
                                    </SimpleItem>
                                    <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                                        editorOptions={{ showClearButton: true, disabled: bill.paymentTypeId == paymentType.credito, }} >
                                        <Label text="Referencia" />
                                    </SimpleItem>
                                
                                    <SimpleItem dataField="observation" colSpan={6} editorType="dxTextBox">
                                        <Label text="Observacion" />
                                        <StringLengthRule max={150} message="Maximo 150 caracteres" />
                                    </SimpleItem>

                                </GroupItem>
                            </GroupItem>                        

                        </GroupItem>
                        <GroupItem colCount={4}>
                            <SimpleItem colSpan={2}>
                                <SelectBox 
                                    ref={refSelect}
                                    dataSource={dataSourceProduct()} 
                                    valueExpr="id" 
                                    displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}
                                    searchEnabled={true} 
                                    searchMode="contains" searchExpr={['name','internalCode']} 
                                    searchTimeout={600} 
                                    placeholder="Selecione una producto" 
                                    showClearButton={true}         
                                    itemRender={itemRender}                           
                                    onValueChanged={e => {

                                        addProductById(e.value);
                                        e.component.reset();
                                        e.component.focus();
                                        //this.props.data.component.editCell(this.props.data.rowIndex, this.quantity);
                                        //refGrid.current.instance.editCell(0, 'quantity');

                                    }} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <DataGrid id="gridDetails"
                                ref={refGrid}                               
                                selection={{ mode: 'single' }}
                                dataSource={ds}
                                showBorders={true}
                                showRowLines={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                height={350}
                                onToolbarPreparing={onToolbarPreparing}
                                onRowInserting={onRowInserting}
                                onCellPrepared={onCellPrepared}
                                onEditCanceled={onEditCanceled}                              
                                onKeyDown={keyDown}
                            >                               
                                <SearchPanel visible={true} />
                                <Column dataField="productId" caption="Producto"
                                    setCellValue={setCellValue.bind(null, "productId")}
                                    editCellComponent={props => <ProductDDBComponent showPrice={true} {...props} validateQuantity={true} />}
                                >
                                
                                    <Lookup
                                        dataSource={products}
                                        valueExpr="id"
                                        displayExpr={item => item ? `${item.internalCode} - ${item.name}` : ''}

                                    />
                                    <RuleRequired />
                                </Column>                          
                                <Column dataField="family" caption={defaultLabels.family} width={120} allowEditing={false}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="quantity" caption="Cantidad" dataType="number" width={80}
                                    setCellValue={setCellValue.bind(null, "quantity")}
                                >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="stock" caption="Disponible" dataType="number" width={90} allowEditing={false}>
                                </Column>
                                <Column visible={false} dataField="price" caption="Precio" dataType="number" width={100} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column dataField="customPrice" caption="Precio" dataType="number" width={100} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "customPrice")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="discount" caption="Desc" dataType="number" width={80} cellRender={cellRender()}
                                    setCellValue={setCellValue.bind(null, "discount")}>
                                    <RuleRequired />
                                </Column>
                                <Column dataField="total" caption="Total" dataType="number" width={120} allowEditing={false} cellRender={cellRender()} >
                                    <RuleRequired />
                                </Column>
                                <Column type="buttons" width={50}>
                                    <ButtonGrid name="delete" />
                                </Column>
                                <Summary>
                                    <TotalItem  column="productId" summaryType="count" />
                                    <TotalItem  column="discount" summaryType="sum" customizeText={toMoney}  />
                                    <TotalItem column="total" summaryType="sum" customizeText={toMoney} />
                                </Summary>
                                <Editing
                                    mode="cell"
                                    allowDeleting={true}
                                    allowUpdating={true}
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                ></Editing>
                            </DataGrid>
                        </GroupItem>
                        <GroupItem colCount={6}>   
                                                
                            <SimpleItem colSpan={2} dataField="codeAuth" editorType="dxTextBox" editorOptions={{readOnly: true}}>
                                <Label text="Código Autorización" />                           
                            </SimpleItem>  
                            <SimpleItem colSpan={2} dataField="asignedTo" editorType="dxSelectBox" editorOptions={{
                                dataSource: users,
                                ...editorOptionsSelect,
                                valueExpr:"username", displayExpr:"username",
                                onValueChanged: e => setBill(_bill =>({ ..._bill, createBy: e.value, }))}}>
                                <Label text="Vendedor" />                           
                            </SimpleItem> 
                            <SimpleItem colSpan={2} itemType="button" buttonOptions={{
                                    text: saving ? 'Guardando...' : `Guardar factura`,
                                    type: 'success',
                                    icon: 'save',
                                    disabled: saving,
                                    visible: isNew,
                                    onClick: guardarFactura
                                }} /> 
                            <SimpleItem visible={bill.active === false} colSpan={6} dataField="disabledSubject" editorOptions={{readOnly: true}}> 
                                <Label text="Anulado" />                           
                            </SimpleItem>                            
                        </GroupItem>
                    </Form>
                    <div className='flex-v'>
                        <TextBox visible={false} focusStateEnabled={true} ref={textBoxRef} onKeyDown={onKeyDown} value={init}></TextBox>                   
                        {
                            bill.active == false && (
                                <label className="text-danger pa-canceled">Factura anulada</label>
                            )
                        }
                        {
                            bill.active == true && !isNew && (
                                <div className='deliveryDateDiv'>
                                    <label>Fecha de entrega</label>
                                    <DateBox ref={refDeliveryDate} value={bill.deliveryDate} showClearButton={true} displayFormat={formatDate} onValueChanged={e => setBill({...bill, deliveryDate: e.value})} />                               
                                    <Button text={saving ? 'Actualizando':'Actualizar fecha'} type="normal" icon="event" onClick={updateDeliveryDate} disabled={saving}></Button>
                                    <Button text={'Imprimir'} type="primary" icon="print" onClick={printBill}></Button>
                                </div>
                            )
                        }
                    </div>
                </ScrollView>

            </Popup>
        </div>
    );
}

export default Nuevo;
