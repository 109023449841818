import React from 'react';
import { useSelector } from 'react-redux';
import useClientDetail from '../../hooks/useClientDetail';
import moment from 'moment';

const ClientInfo = ({ isMobile= false, user='' }) => {

    const { clientId } = useSelector(store => store.clientDetail);
   
    const { data, isLoading } = useClientDetail({ clientId });

    const { client , saldo, date, dueBills} = data;

    const creditLimit = client?.creditLimit ?? '--';
    const typeId = client?.typeId ?? '--';
    const saldoDisponible = saldo || '--';
    let dateUltCompra = '--';
    
    if(date)
        dateUltCompra = moment(date).format('DD-MM-YYYY');
    
    return (
        <div>
            {isLoading ? 
                'Cargando...' : 
                <table>
                    {
                        isMobile && 
                        <tbody>
                            <tr>
                                <td className='text-blue'>Crédito:</td>
                                <td className='pr-30'><b>{creditLimit}</b></td> 
                                <td className='text-blue'>Tipo:</td>
                                <td className='pr-30'><b>{typeId === 1 ? 'Cuota Fija' : typeId == 2 ? 'Regimen General': '--'}</b></td>
                            </tr>                            
                            <tr>
                                <td className='text-blue'>Disponible:</td>
                                <td><b>{saldo < 0 ? <div className ="text-danger">{saldoDisponible}</div>:<div>{saldoDisponible}</div>}</b></td>        
                                <td className='text-blue'>Últ Compra:</td>
                                <td><b>{dateUltCompra}</b></td>                         
                            </tr>
                            <tr>                               
                                <td className='text-blue'>Fact Vencidas:</td>
                                <td className='pr-30'><b>{dueBills}</b></td>      
                            </tr>                          
                            <tr>
                                <td className='text-blue'>Dirección:</td>
                                <td colSpan={3} className='pr-30'><b>{client?.address}</b></td>      
                            </tr>
                        </tbody>
                    }
                    {
                        !isMobile && 
                        <tbody>
                            <tr>
                                <td className='text-blue'>Limite crédito:</td>
                                <td className='pr-30'><b>{creditLimit}</b></td>
                                <td className='text-blue'>Tipo:</td>
                                <td className='pr-30'><b>{typeId === 1 ? 'Cuota Fija' : typeId == 2 ? 'Regimen General': '--'}</b></td>
                                <td className='text-blue'>Facturas Vencidas:</td>
                                <td className='pr-30'><b>{dueBills}</b></td>                       
                            </tr> 
                            <tr>
                                <td className='text-blue'>Saldo Disponible:</td>
                                <td><b>{saldo < 0 ? <div className ="text-danger">{saldoDisponible}</div>:<div>{saldoDisponible}</div>}</b></td>                      
                                <td className='text-blue'>Ult compra:</td>
                                <td><b>{dateUltCompra}</b></td>
                                <td className='text-blue'>Vendedor:</td>
                                <td><b>{user}</b></td>
                            </tr>
                            <tr>
                                <td className='text-blue'>Dirección:</td>
                                <td colSpan={4} className='pr-30'><b>{client?.address}</b></td>      
                            </tr>
                        </tbody>
                    }                   
                </table>
            }
        </div>
    );
}

export default ClientInfo;
