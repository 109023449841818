import React, { useEffect, useState, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, Label, RequiredRule, StringLengthRule, EmptyItem} from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux'
import { closeDialogRecibo } from '../../store/dialogRecibo/dialogReciboReducer';
import DataSource from "devextreme/data/data_source";
import { createStore, createStoreLocal } from '../../utils/proxy';
import { dataAccess, editorOptionsSelect, formatDate, resources } from '../../data/app';
import { DataGrid, Button } from 'devextreme-react';
import { Column, Editing, Lookup, RequiredRule as RuleRequired, Button as ButtonGrid } from 'devextreme-react/data-grid';
import uri from '../../utils/uri';
import toCapital, { cellRender } from '../../utils/common';
import http from '../../utils/http';
import notify from 'devextreme/ui/notify';
import { billDefault, receiptDefault } from '../../data/defaultObjects';
import BillByClient from '../../components/bill/BillByClient';
import useAuthorization from '../../hooks/useAuthorization';

const Nuevo = (props) => {

    const [ authorized, can ] = useAuthorization( resources.crearRecibo );

    const { data, onSave, close } = props;
    const { id, billId, open, client, balance} = data;
  
    const [ saving, setSaving ] = useState(false);
    const [ receipt, setReceipt ] = useState({...receiptDefault});

    const refForm = useRef();

    const closeDialog = ( load ) => {       

        close();
        if (load) {
            onSave();      
        }        
    }

    const onHiding = ({ load }) => {       
        closeDialog(load);        
    } 

    useEffect(() => { 

        setReceipt({...receipt, id, billId, client, balance, amount : 0, observation : '', applyPayment : false,paymentMethodId:null, billPaymentMethodId : null, bankId : null, reference : '', referencePhysical : ''});
        refForm.current.instance.resetValues();
        if(id > 0)
            http(uri.receivables.getById(id)).asGet().then(resp => {

                setReceipt({...resp});
                closeDialog(true);

            });

    }, [id, billId]);

    const guardarRecibo = (e) => {

        let result = refForm.current.instance.validate();

        if (result.isValid) {

            setSaving(true);            

            http(uri.receivables.insert).asPost(receipt).then(resp => {
                setSaving(false);
                notify(`Recibo ${resp.id} registrado correctamente`);
                closeDialog(true);
            }).catch(err => {
                setSaving(false);
                notify(err, 'error', 5000);
            });

        }

    }

    const onValueChanged = (e) => {
        setReceipt({...receipt, amount : e.value ? balance : 0 });       
    }

    return (
        <div>
            <Popup
                width={650}
                height={430}
                title={id > 0  ? `Ver recibo` : `Nuevo recibo`}
                onHiding={onHiding}
                visible={open}   
                className="bg-fbfbfb"             
            >
                {authorized(<><Form formData={receipt} ref={refForm}>
                    <GroupItem colCount={4}>
                        <SimpleItem dataField="billId" colSpan={2} 
                            editorOptions={{
                                disabled : true
                            }} >
                            <Label text="Factura#" />
                        </SimpleItem>
                        <EmptyItem colSpan={2} />
                        <SimpleItem dataField="client" colSpan={4} 
                            editorOptions={{
                                disabled : true
                            }} >
                            <Label text="Cliente" />
                        </SimpleItem>
                        <SimpleItem dataField="balance"  colSpan={2} editorType="dxNumberBox"
                            editorOptions={{
                               readOnly : true,
                               format: "#,##0.00"
                            }} >
                            <Label text="Pendiente" />
                        </SimpleItem>
                        <SimpleItem dataField="amount"  colSpan={2} editorType="dxNumberBox" editorOptions={{
                            readOnly : receipt.applyPayment,
                            format: "#,##0.00"
                        }}>
                            <Label text="Monto pagado" />
                            <RequiredRule message="El monto es requerido" />
                        </SimpleItem>
                        <SimpleItem dataField="referencePhysical" colSpan={2} editorType="dxTextBox"
                            editorOptions={{ showClearButton: true }} >
                            <Label text="# Recibo" />
                            <RequiredRule message="Seleccione el tipo" />
                        </SimpleItem>
                        <SimpleItem dataField="paymentMethodId" colSpan={2} editorType="dxSelectBox"
                            editorOptions={{                               
                                dataSource: createStoreLocal({ name: 'billPaymentMethod' }),
                                ...editorOptionsSelect,
                                //onValueChanged: e => setBill(_bill =>({ ..._bill, billPaymentTypeId: e.value, }))
                            }} >
                            <Label text="Forma pago" />
                        </SimpleItem>
                        <SimpleItem dataField="bankId" colSpan={2} editorType="dxSelectBox"
                            editorOptions={{                               
                                dataSource: createStoreLocal({ name: 'bank' }),
                                ...editorOptionsSelect,
                                //onValueChanged: e => setBill(_bill =>({ ..._bill, bankId: e.value, }))
                            }} >
                            <Label text="Banco" />
                        </SimpleItem>
                        <SimpleItem dataField="reference" colSpan={2} editorType="dxTextBox"
                            editorOptions={{ showClearButton: true }} >
                            <Label text="Referencia" />
                        </SimpleItem>
                        <SimpleItem dataField="applyPayment" colSpan={4}  editorType="dxCheckBox" editorOptions={{ onValueChanged: onValueChanged }}>
                            <Label text="Aplicar pago completo" />
                        </SimpleItem>
                        <SimpleItem dataField="observation" colSpan={4}  editorType="dxTextArea">
                            <Label text="Observacion" />
                            <RequiredRule message="Ingrese una observacion" />
                            <StringLengthRule max={150} message="Maximo 150 caracteres" />
                        </SimpleItem>
                    </GroupItem>                    
                </Form>         
                <br/>        
                <Button
                    className="mt-10"
                    text={saving ? 'Guardando...' : `Guardar recibo`}
                    type="success"
                    icon='save'
                    disabled={saving}
                    onClick={guardarRecibo}
                /></>)}   
            </Popup>
        </div>
    );
}

export default Nuevo;
