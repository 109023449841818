export const editorOptions = { stylingMode: "filled" };

export const editorOptionsSelect = {
    valueExpr: "id",
    displayExpr: "name",
    searchEnabled: true,    
}

export const editorOptionsSwitch = {
    switchedOffText:"NO",
    switchedOnText:"SI",
}

export const editorOptionsNumberBox={
    showSpinButtons:true,
    showClearButton:true
}

export const editorOptsTextBoxPhone={
    mask: "0000-0000"
}

export const editorOptsTextBox={
    showClearButton:true
}

export const cssClasses = {
    0 : '',
    1 : 'custome-active',
    2 : 'custome-noactive',
}

export const formatDateTime = 'dd/MM/yyyy hh:mm a';
export const formatDate = 'dd/MM/yyyy';

export const areaRestrict = {
    bodega : 1
}

export const resources = {   

    verTodasFacturas : 1,   
    inventario : 2,
    ajuste : 3,
    requisa : 4,
    ordenesDeCompra : 5,
    crearCompra : 6, //asignar al boton
    adminCatalogos : 7,
    adminUsuario : 8,
    verClientes : 9,
    crearRecibo : 10,
    app : 11,
    cotizacion: 12,
    reporteCompra : 13,
    auths : 14,
    cuentasPorCobrar: 18,
    verRecibos: 19,
    verMisFacturas: 20,
    crearFactura: 21,
    crearClienteEnPreventa: 22,
    crearClienteConCreditos: 23,
    administrarProveedores: 24,
    adminTasaCambios: 27,
    verMisCuentasXCobrar: 28,
    gestionCierreCaja: 29,
    configurarComisiones: 30,
    cambiarDiasCredito: 31,
    configurarCredito: 32,
    crearNotasCreditos: 33,
    verReporteStock: 34,
    verExistencias: 35,
    verKardex: 36,
    verColumnaCostoStock: 37,

}


export const dataAccess = {
    none : 0x0,
    access : 0x01,
    create : 0x02,
    update : 0x04,
    delete : 0x08
}

export const calculateEvaluate = (cost, rule) => {

    const stringRule = cost + rule;

    try {

        const result = eval(stringRule);

        return result;

    } catch (error) {
        return null;
    }

}






